<template>
  <div
    id="users"
    class="page-container-table"
  >
    <!--phần header-->
    <button-all-header
      :contentBtnAdd="'Thêm người dùng'"
      :arrayExcel="arrayExcel"
      :showBtnMultiDelete="showBtnMultiDelete"
      @clickDelete="deleteItems"
      @clickDowloadSample="downloadExampleFileUsers"
      @clickExportExcel="downloadSampleUsers"
      @importFile="importFileExcel($event)"
      @clickAdd="$router.push({name: 'create-user'})"
      @search="search($event)"
    />

    <vue-good-table
      ref="user-table"
      style-class="vgt-table"
      :columns="columns"
      :rows="dataListUsers || []"
      :select-options="{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      @on-selected-rows-change="selectRowTable"
    >
      <!-- Slot: Table Row -->
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column:Mã người dùng -->
        <span
          v-if="props.column.field === 'code'"
          class="text-nowrap"
        >
          <b-badge
            pill
            class="light-code"
          >
            <span class="text-name">{{ props.row.code }}</span>
          </b-badge>
        </span>

        <!-- Column:Tên người dùng -->
        <span
          v-else-if="props.column.field === 'fullName'"
          class="text-nowrap"
        >
          <b-media>
            <template #aside>
              <user-avatar :data="props.row" />
            </template>
            <span class="font-weight-bold text-name">{{ props.row.name }}</span>
            <span
              v-b-tooltip.hover.top="props.row.isOnline ? 'Online' : 'Offline'"
              class="dot ml-1"
              :class="props.row.isOnline ? '' : 'off'"
            ></span>
            <div>
              <span>{{ props.row.userName }}</span>
            </div>
          </b-media>
        </span>

        <!-- Column:ngày tạo -->
        <span v-else-if="props.column.field === 'registeredDate'">
          <span class="text-nowrap">{{ props.row.created | formatDateToDDMM }}</span>
        </span>

        <!-- Column:trạng thái khóa -->
        <span v-else-if="props.column.field === 'lockoutEnd'">
          <feather-icon
            v-if="props.row.lockoutEnd"
            v-b-tooltip.hover.top="'Đã khóa tài khoản'"
            icon="LockIcon"
            size="18"
            class="text-body"
          />
        </span>

        <!-- Column:Trạng thái -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge
            pill
            :variant="statusVariant(props.row.emailConfirmed)"
            class="border-status"
          >
            <span>{{ props.row.emailConfirmed === true ? 'Đã kích hoạt' : 'Chưa kích hoạt' }}</span>

          </b-badge>
        </span>
        <!-- Column:Chức năng -->
        <span v-else-if="props.column.field === 'actionFunction'">
          <feather-icon
            v-b-tooltip.hover.top="'Chỉnh sửa'"
            icon="EditIcon"
            size="18"
            class="text-body"
            @click="$router.push({name: 'edit-user', params: {id: props.row.id, type: 'edit'}})"
          />
          <feather-icon
            v-b-tooltip.hover.top="'Xóa'"
            icon="Trash2Icon"
            size="18"
            class="text-body ml-2"
            @click="deleteItem(props.row.id)"
          />
          <b-dropdown
            variant="link"
            boundary="viewport"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="18"
                class="text-body"
              />
            </template>
            <!-- xem chi tiet  -->
            <b-dropdown-item
              @click="handleActive(props.row.userId, props.row.emailConfirmed)"
            >
              <feather-icon
                v-if="!props.row.emailConfirmed"
                icon="CheckCircleIcon"
                size="16"
                class="mr-50"
              />
              <feather-icon
                v-if="props.row.emailConfirmed"
                icon="XCircleIcon"
                size="16"
                class="mr-50"
              />
              <span>{{ props.row.emailConfirmed === false ? 'Kích hoạt' : 'Hủy kích hoạt' }}</span>
              <!-- <span>{{ $t("common.action-table.see-detail") }}</span> -->
            </b-dropdown-item>
            <!-- xem bao cao  -->
            <b-dropdown-item @click="handleOpenModal(props.row.userId)">
              <feather-icon
                icon="LockIcon"
                size="16"
                class="mr-50"
              />
              <span>Cấp mật khẩu</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="props.row.lockoutEnd"
              @click="openUnlockAccount(props.row.userId)"
            >
              <feather-icon
                icon="UnlockIcon"
                size="16"
                class="mr-50"
              />
              <span>Mở khóa tài khoản</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-else
              @click="openLockAccount(props.row.userId)"
            >
              <feather-icon
                icon="LockIcon"
                size="16"
                class="mr-50"
              />
              <span>Khóa tài khoản</span>
            </b-dropdown-item>
          </b-dropdown>
        </span>
      </template>
    </vue-good-table>

    <!-- pagination -->
    <my-pagination
      :totalItems="totalRecord"
      :currentPage="urlQuery.pageNumber"
      @pageClick="handlePageClick"
    />

    <!-- modal xóa người dùng-->
    <confirm-modal
      :id="confirmModalId"
      :content="modalContent"
      @accept="deleteAction"
    />

    <!-- modal mở khóa tài khoản-->
    <confirm-modal
      :id="confirmModalUnlock"
      content="Bạn có chắc chắn mở khóa?"
      @accept="handleUnlockAccount"
    />

    <!-- modal khóa tài khoản-->
    <confirm-modal
      :id="confirmModalLock"
      content="Bạn có chắc chắn khóa?"
      @accept="handleLockAccount"
    />

    <!-- modal cấp lại mật khẩu -->
    <modal-reissue-password
      id="modalPasswoord"
      @handleReissuePassword="handleReissuePassword"
    />
  </div>
</template>

<script>
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import { mapActions } from 'vuex'
import {
  BBadge,
  BMedia,
  BDropdown,
  BDropdownItem,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'

import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import UserAvatar from '@/components/avatar/UserAvatar.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import ModalReissuePassword from '@/@core/layouts/components/app-navbar/components/ModalReissuePassword.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    VueGoodTable,
    BBadge,
    BMedia,
    MyPagination,
    UserAvatar,
    BDropdown,
    BDropdownItem,
    FeatherIcon,
    ConfirmModal,
    ModalReissuePassword,
    ButtonAllHeader,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      formatFullName,
      columns: [
        {
          label: 'MÃ NGƯỜI DÙNG',
          field: 'code',
          sortable: false,
        },
        {
          label: 'TÊN NGƯỜI DÙNG',
          field: 'fullName',
          sortable: false,
        },
        {
          label: 'KIỂU NGƯỜI DÙNG',
          field: 'userType',
          sortable: false,
        },
        {
          label: 'CƠ CẤU TỔ CHỨC',
          field: 'organizationStructure',
          sortable: false,
        },
        {
          label: 'NGÀY THAM GIA',
          field: 'registeredDate',
          sortable: false,
        },
        {
          label: 'TRẠNG THÁI',
          field: 'status',
          sortable: false,
        },
        {
          label: 'TRẠNG THÁI KHÓA',
          field: 'lockoutEnd',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'actionFunction',
          width: '175px',
          sortable: false,
        },
      ],
      currentPage: 0,
      urlQuery: {
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      confirmModalId: 'confirm-modal',
      confirmModalUnlock: 'confirm-modal-unlock',
      confirmModalLock: 'confirm-modal-lock',
      modalContent: '',
      showBtnMultiDelete: false,
      deleteIds: [],
      isActive: false,
      userId: '',
      arrayExcel: [
        'code',
        'userName',
        'password',
        'name',
        'email',
        'birthDate',
        'gender',
        'address',
        'phoneNumber',
        'userType',
        'groupUser',
        'position',
        'orStruc'],
      dataListUsers: [],
      totalRecord: 0,
      accountId: '',
    }
  },
  computed: {
    // ...mapGetters('users', ['dataListUsers', 'totalRecord']),
    statusVariant() {
      const statusColor = {
        // Chưa kích hoạt
        false: 'light-danger',
        // Kích hoạt
        true: 'light-success',
      }
      return status => statusColor[status]
    },
  },
  created() {
    this.fetchListUsers(this.urlQuery)
  },
  methods: {
    ...mapActions('users', [
      'downloadSampleUsers',
      'getApiExcelUser',
      'downloadExampleFileUsers',
    ]),

    // Mở modal mở khóa tài khoản
    openUnlockAccount(id) {
      this.accountId = id
      this.$bvModal.show(this.confirmModalUnlock)
    },

    // Mở khóa tài khoản
    async handleUnlockAccount() {
      await axiosApiInstance.post(`${ConstantsApi.UNLOCK_ACCOUNT}${this.accountId}`).then(res => {
        if (res.status === 200) {
          this.$root.$bvToast.toast('Mở khóa thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.fetchListUsers(this.urlQuery)
        }
      }).catch(e => {
        this.$root.$bvToast.toast('Mở khóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Mở modal khóa tài khoản
    openLockAccount(id) {
      this.accountId = id
      this.$bvModal.show(this.confirmModalLock)
    },

    // Khóa tài khoản
    async handleLockAccount() {
      await axiosApiInstance.post(`${ConstantsApi.LOCK_ACCOUNT}${this.accountId}`).then(res => {
        if (res.status === 200) {
          this.$root.$bvToast.toast('Khóa thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.fetchListUsers(this.urlQuery)
        }
      }).catch(e => {
        this.$root.$bvToast.toast('Khóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Fetch data
    async fetchListUsers(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_USERS, {
        params: urlQuery,
      })
      this.dataListUsers = data.data.pageLists
      this.totalRecord = data.data.totalRecord
      this.$hideLoading()
    },

    async handleActive(id, check) {
      if (check === false) {
        await axiosApiInstance.post(`${ConstantsApi.ACTIVE_ACCOUNT}${id}`).then(res => {
          if (res.status === 200) {
            this.$root.$bvToast.toast('Kích hoạt thành công', {
              title: 'Thông báo',
              variant: 'success',
              toaster: this.$toastPosition,
              solid: true,
            })
            this.fetchListUsers(this.urlQuery)
          }
        }).catch(e => {
          this.$root.$bvToast.toast('Kích hoạt không thành công', {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        })
      } else {
        await axiosApiInstance.post(`${ConstantsApi.UN_ACTIVE_ACCOUNT}${id}`).then(res => {
          if (res.status === 200) {
            this.$root.$bvToast.toast('Hủy kích hoạt thành công', {
              title: 'Thông báo',
              variant: 'success',
              toaster: this.$toastPosition,
              solid: true,
            })
            this.isActive = false
            this.fetchListUsers(this.urlQuery)
          }
        }).catch(e => {
          this.$root.$bvToast.toast('Hủy kích hoạt không thành công', {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        })
      }
    },

    // Xử lý khi ấn nút phân trang
    handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchListUsers(this.urlQuery)
    },

    // tìm kiếm lộ trình đào tạo
    search(val) {
      // if (val != null) {
      //   this.urlQuery.pageNumber = 1
      //   this.urlQuery.key = val
      //   this.fetchListUsers(this.urlQuery)
      // } else {
      this.urlQuery.pageNumber = 1
      this.urlQuery.key = val
      this.fetchListUsers(this.urlQuery)
      // }
    },

    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    // xóa 1 người dùng
    async deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // Xóa người dùng của hệ thống
    async deleteAction() {
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DELETE_USERS, this.deleteIds).then(() => {
        this.$root.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchListUsers(this.urlQuery)
      }).catch(e => {
        this.$root.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Mở modal
    handleOpenModal(id) {
      this.userId = id
      this.$bvModal.show('modalPasswoord')
    },

    // Cấp lại mật khẩu cho người dùng
    async handleReissuePassword(val, validate) {
      const payload = {
        ...val,
        userId: this.userId,
      }
      validate.validate().then(async success => {
        if (success) {
          await axiosApiInstance.post(ConstantsApi.CHANGE_PASSWORD, payload).then(res => {
            this.$hideAllPageLoading()
            if (res.status === 200) {
              this.$root.$bvToast.toast('Cập nhật thành công', {
                title: 'Thông báo',
                variant: 'success',
                toaster: this.$toastPosition,
                solid: true,
              })
              val.confirmNewPassword = ''
              val.newPassword = ''
              this.$bvModal.hide('modalPasswoord')
            }
          })
        }
      }).catch(e => {
        this.$root.$bvToast.toast(e.response.data?.errors.ConfirmNewPassword[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // import file exel
    async importFileExcel(event) {
      if (event) {
        this.getValidData(event)
      }
    },
    // kiểm tra hợp lệ từ file exel
    async getValidData(dataInput) {
      const model = {
        listExcel: dataInput,
        isValidate: true,
      }
      await this.getApiExcelUser(model)
      await this.$router.push({ name: 'import-user' })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

#users {
  .dot {
    height: 10px;
    width: 10px;
    background-color: rgb(63, 192, 74);
    border-radius: 50%;
    display: inline-block;
  }

  .off {
    background-color: rgb(226, 61, 61);
  }

  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }

  .button-icon {
    .btn-component {
      width: 38px;
      height: 38px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 11px;
    }
  }

  .button-icon-custom {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
  }

  .search-input {
    width: 400px;
  }

  .button-import {
    .input-file {
      display: none;
    }
  }

  .btn-icon-file {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
    label {
      margin-bottom: unset !important;
    }
  }
}
</style>
